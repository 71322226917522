import React from "react";
import { Gallery } from "react-photoswipe-gallery";
import KnowledgeInterest from "./about/KnowledgeInterest";

const Portfolio = () => {
  return (
    <>
      <Gallery>
        <div className="container">
          <div className="tokyo_tm_portfolio">
            <div className="tokyo_tm_title">
              <div className="title_flex">
                <div className="left">
                  <span>Developer Portfolio</span>
                </div>
              </div>
            </div>
            {/* END TOKYO_TM_TITLE */}

            <div className="tokyo_tm_skillbox">
              <div className="tokyo_tm_title">
                <h3>Technical Skills</h3>
              </div>

              <div className="container">
                <div className="in">
                  <KnowledgeInterest />
                </div>
              </div>

              <div className="tokyo_tm_title">
                <h3>Deployed Projects</h3>
                <i>Unfortunately, the code is unavilable to view for these projects as they were developed for other people.</i>
                </div>
              </div>

              <div className="container">
                <div className="in">

                <ul data-aos="fade-right" data-aos-duration="1200">
                    <div className="inner">
                      <div className="entry tokyo_tm_portfolio_animation_wrap">
                        <h3>Cintrex Property Management CRM</h3>
                        <p>Create, manage, schedule and view work orders.</p>
                      </div>
                    </div>
                  </ul><br/>

                  <ul data-aos="fade-right" data-aos-duration="1200">
                    <div className="inner">
                      <div className="entry tokyo_tm_portfolio_animation_wrap">
                        <h3>Fogain Accounting CRM</h3>
                        <p>Connected all of the accounting firm's software in one central hub.</p>
                      </div>
                    </div>
                  </ul><br/>

                  <ul data-aos="fade-right" data-aos-duration="1200">
                    <div className="inner">
                      <div className="entry tokyo_tm_portfolio_animation_wrap">
                        <h3>Queuetrack Mobile App</h3>
                        <p>User-based contribution system for tracking bar lines.</p>
                      </div>
                    </div>
                  </ul><br/>

                  <ul data-aos="fade-right" data-aos-duration="1200">
                    <div className="inner">
                      <div className="entry tokyo_tm_portfolio_animation_wrap">
                        <h3>Otom AI WebApp</h3>
                        <p>AI interface for accountants - made when AI was relatively new to the market.</p>
                      </div>
                    </div>
                  </ul><br/>

                  <ul data-aos="fade-right" data-aos-duration="1200">
                    <div className="inner">
                      <div className="entry tokyo_tm_portfolio_animation_wrap">
                        <h3>Intellisync WebApp</h3>
                        <p>Navigated a complex repository and added new features to an exisitng product.</p>
                      </div>
                    </div>
                  </ul><br/>

                  <ul data-aos="fade-right" data-aos-duration="1200">
                    <div className="inner">
                      <div className="entry tokyo_tm_portfolio_animation_wrap">
                        <h3>20+ Websites</h3>
                        <p>Built and deployed websites for businesses & creators across various industries. Most were built with typescript, and some with wordpress.</p>
                      </div>
                    </div>
                  </ul><br/>

                </div>
              </div>
            </div>
          </div>
      </Gallery>
    </>
  );
};

export default Portfolio;
