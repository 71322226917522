import React from "react";
import Social from "./Social";

const Home = () => {
  return (
    <>
      <div className="tokyo_tm_home">
        <div className="home_content">
          <div className="details">
            <div className="info">
              <h3 className="name">Dan Gomes</h3>
              <p className="job">
                passionate about code and media.
              </p>
              </div>
              <div className="socials">
              <Social />
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

