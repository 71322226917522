import React from "react";

const Intro = () => {
  const introContent = {
    name: "My journey so far.",
    text: (
      <>
      <p>
          I have been passionate about the tech space for as long as I can remember - everything from code to media.
        </p>
        <p>
          Currently wrapping up the 4th year of my computer science degree.
        </p>
        <p>
          Much of my experience with software came from starting a development agency during my studies, building and deploying real projects for real people.
        </p>
        <p>
          I've also experimented with creating content, via social media and newsletters. I love the idea of spreading knowledge in a meaningful way.
        </p>
      </>
    ),
  };

  return (
    <>
      <div className="top_author_image">
      </div>
      <div className="about_title">
        <h3>{introContent.name}</h3>
      </div>
      <div className="about_text">{introContent.text}</div>
    </>
  );
};

export default Intro;
