import React from "react";
import Modal from "react-modal";
import Logo from "../assets/newsletter/digital_leverage_letter.png"

Modal.setAppElement("#root");

const News = () => {
  return (
    <>
      <div className="container">
        <div className="tokyo_tm_news">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>The Digital Leverage Letter</span>
                <h3>My insights on online business, the power of code and media, and skills to leverage the potential of the internet.</h3>
                <img src={Logo} alt="logo" style={{paddingTop: "50px"}}/>
                <h4>coming soon.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
